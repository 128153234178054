import { Emphasis } from "@lya-protect/lya-protect-form-library/dist/UI";
import { InfoBulle } from "@src/InfoBulle";
import { InfoBulleButton } from "@src/InfoBulleButton";
import { PopperButton } from "@src/PopperButton";
import classNames from "classnames";
import { Card } from "./Card";
import "./recapCard.scss";
import { formatCurrency } from "./utils";

type RecapCardProps = {
  age: number;
  couvertureName: string;
  simulationCotisationMonth: number;
  className?: string;
};

export function RecapCard({
  className,
  age,
  couvertureName,
  simulationCotisationMonth,
}: RecapCardProps) {
  return (
    <Card className={classNames("recap-card", className)}>
      <p className="recap-card__first-recap">
        Pour une souscription à l&apos;âge de{" "}
        <Emphasis>{`${age} ans`}</Emphasis>
        ,
        <br />
        et pour un{" "}
        <Emphasis>{`${couvertureName.toLowerCase()} de couverture`}</Emphasis> :
      </p>
      <p className="recap-card__second-recap">
        Votre cotisation s&apos;élèvera à{" "}
        <Emphasis>{`${formatCurrency(
          simulationCotisationMonth
        )} TTC par mois`}</Emphasis>
        ,
        <br />
        soit{" "}
        <Emphasis>{`${formatCurrency(
          simulationCotisationMonth * 12
        )} TTC par an.`}</Emphasis>
        <PopperButton
          popperPlacement="top-end"
          openOnHover
          withArrow
          renderButton={(props) => (
            <InfoBulleButton size="large" {...props}>
              {" "}
            </InfoBulleButton>
          )}
          renderPopperContent={({ setVisible }) => (
            <InfoBulle
              className="recap-card__info-bulle"
              onCancel={() => setVisible(false)}
            >
              Les cotisations sont calculées sur la base d'informations<br/>
              que vous nous avez fournies et conformément aux tarifs en vigueur<br/>
              valables jusqu'au 30/11/2025, sous réserve de toute évolution
            </InfoBulle>
          )}
        />
      </p>
      <p>
        <br />
        Ce tarif comprend les prestations suivantes :{" "}
        <PopperButton
          popperPlacement="top-end"
          openOnHover
          withArrow
          renderButton={(props) => (
            <InfoBulleButton size="large" {...props}>
              {" "}
            </InfoBulleButton>
          )}
          renderPopperContent={({ setVisible }) => (
            <InfoBulle
              className="recap-card__info-bulle"
              onCancel={() => setVisible(false)}
            >
              Les garanties dépendances sont assurées et réalisées par
              Tutélaire, Mutuelle soumise aux dispositions du livre II du Code
              de la mutualité, dont le siège social est situé 157 avenue de
              France – 75013 Paris et immatriculée sous le numéro Siren 775 682
              164. <br /> <br />
              Les garanties service et protection juridique sont assurées et
              réalisées par Solucia Protection Juridiques, société anonyme à
              directoire et conseil de surveillance au capital social de 9 600
              000 € régie par le Code des assurances, dont le siège social est
              situé 111 avenue de France – CS 51519 – 75634 Paris cedex 13 et
              immatriculée au RCS de Paris sous le numéro Siren 481 997 708.
              <br />
              <br />
              Les garanties d'assistance sont assurées et réalisées par
              Ressources Mutuelles Assistances, union d’assistance soumise aux
              dispositions du Livre II du Code de la mutualité, dont le siège
              social est situé 46 rue du Moulin - CS 32427 - 44124 Vertou Cedex
              et immatriculée sous le numéro Siren 444 269 682.
            </InfoBulle>
          )}
        />
        <ul>
          <li>Garanties dépendance (capital forfaitaire + rente mensuelle)</li>
          <li>Garanties d’assistance</li>
          <li>Garanties service et protection juridiques</li>
        </ul>
      </p>
    </Card>
  );
}
