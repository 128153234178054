import { captureException } from "@sentry/react";
import { GarantieAssistanceModal } from "@src/GarantieAssistanceModal";
import { GarantieProtectionJuridiqueModal } from "@src/GarantieProtectionJuridique";
import { LoaderOverlay } from "@src/LoaderOverlay";
import { Modal } from "@src/Modal";
import { displayToast } from "@src/Toaster";
import axios from "axios";
import { format, getYear, startOfMonth, add, differenceInYears } from "date-fns"; // eslint-disable-line import/no-duplicates
import { useEffect, useState } from "react";
import fr from "date-fns/locale/fr"; // eslint-disable-line import/no-duplicates

import { Desktop, Mobile } from "@lya-protect/lya-protect-form-library/dist/UI";
import { StepProps } from "./Steps";
import { TarifDesktop } from "./TarifDesktop";
import { TarifMobile } from "./TarifMobile";

type TarifResponse = Tarif[];

export type Tarif = {
  name: string;
  capitalForfaitaire: string;
  renteMensuelle: string;
  simulationCotisation: number;
};

type LandingProps = StepProps<Tarif | undefined>;

export function Tarif({ changeStep, setData, stepsData }: LandingProps) {
  const [tarifs, setTarifs] = useState<TarifResponse>();
  const [modalGassitanceIsOpen, setModalGassitanceIsOpen] = useState(false);
  const [modalPJuridiqueIsOpen, setModalPJuridiqueIsOpen] = useState(false);
  const [renderIsComplete, setRenderIsComplete] = useState(false);

  const nextMonth = add(startOfMonth(new Date()), {months: 1});
  const age = stepsData.BIRTHDATE.birthDate && differenceInYears(nextMonth, stepsData.BIRTHDATE.birthDate);

  const selectTarif = (tarif: Tarif) => () => {
    setData(tarif);
    changeStep("RECAP", "forward");
  };

  useEffect(() => {
    if (!stepsData.BIRTHDATE.birthDate) return;
    axios
      .post<TarifResponse>(`//${process.env.VITE_URL_GATEWAY}/tarif`, {
        birthDate: format(stepsData.BIRTHDATE.birthDate, "yyyy-MM-dd", {
          locale: fr,
        }),
        adherent: stepsData.SITUATION?.isMemberTutelaire,
      })
      .then(({ data }) => {
        setTarifs(data);
      })
      .catch((err) => {
        displayToast("Une erreur inconnue est survenue", "error");
        changeStep("BIRTHDATE", "backward");
        captureException(err);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (tarifs && age !== undefined) setRenderIsComplete(true);
  }, [tarifs]);

  return (
    <>
      <LoaderOverlay isLoading={!renderIsComplete} />
      {tarifs && age !== undefined && (
        <>
          <Desktop>
            <TarifDesktop
              age={age}
              tarifs={tarifs}
              selectTarif={selectTarif}
              changeStep={changeStep}
              openModalPJuridique={() => setModalPJuridiqueIsOpen(true)}
              openModalGassistance={() => setModalGassitanceIsOpen(true)}
            />
          </Desktop>
          <Mobile>
            <TarifMobile
              age={age}
              tarifs={tarifs}
              selectTarif={selectTarif}
              changeStep={changeStep}
              openModalPJuridique={() => setModalPJuridiqueIsOpen(true)}
              openModalGassistance={() => setModalGassitanceIsOpen(true)}
            />
          </Mobile>
        </>
      )}
      <Modal
        isOpen={modalGassitanceIsOpen}
        Content={GarantieAssistanceModal}
        close={() => setModalGassitanceIsOpen(false)}
      />
      <Modal
        isOpen={modalPJuridiqueIsOpen}
        Content={GarantieProtectionJuridiqueModal}
        close={() => setModalPJuridiqueIsOpen(false)}
      />
    </>
  );
}
