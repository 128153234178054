import { FooterAMELLISByStepName } from "@src/Footer/AMELLIS/FooterAMELLISByStepName";
import { StepName } from "@src/Steps/Steps";
import { MouseEvent } from "react";

type FooterLegalAmellisProps = {
  stepName: StepName;
};

export function ProtectionDonneesAmellis() {
  const openMailTo = (e: MouseEvent) => {
    window.open("mailto:donneespersonnelles@tutelaire.fr", "mail");
    e.preventDefault();
  };

  return (
    <p>
      <small>
        Le contrat sâge autonomie vous est proposé par Amellis Mutuelles,
        mutuelle soumise aux dispositions du livre II du Code de la mutualité,
        agissant en qualité de distributeur de Tutélaire, dont le siège social
        est situé dont le siège social est situé 8-12 rue de la Poyat, 39200
        Saint-Claude et immatriculée sous le numéro Siren 775 597 768. Données
        personnelles : Vous disposez des droits d'accès, de rectification,
        d'opposition, de portabilité, d'effacement et de limitation du
        traitement vous concernant.
        <br />
        Pour l'exercice de vos droits, adressez un courrier simple au
        responsable de traitement : Tutélaire, 157 avenue de France, 75013
        Paris, ou un courriel à :{" "}
        <a
          href="mailto:donneespersonnelles@tutelaire.fr"
          target="_blank"
          rel="noreferrer"
          onClick={openMailTo}
        >
          donneespersonnelles@tutelaire.fr
        </a>
        .
        <br />
        Pour plus d'informations relatives à la gestion de vos données, vous
        pouvez à tout moment consulter notre politique de confidentialité sur le
        site internet{" "}
        <a href="https://www.tutelaire.fr" target="_blank" rel="noreferrer">
          www.tutelaire.fr
        </a>{" "}
        ou la demander par courrier simple au responsable de traitement de
        Tutélaire.
      </small>
    </p>
  );
}

export function FooterLegalAmellis({ stepName }: FooterLegalAmellisProps) {
  return (
    <>
      <div className="footer-legal__part">
        <FooterAMELLISByStepName stepName={stepName} />
      </div>
      <div className="footer-legal__part">
        <div className="footer-legal__logos">
          <img
            className="footer-legal__logo-tutelaire"
            alt="logo tutelaire"
            src="/logo-tutelaire.png"
          />
          <img
            className="footer-legal__logo-mutualite"
            alt="logo mutualité francaise"
            src="/logo-mutualite-francaise.png"
          />
        </div>
        <div className="stepper__mentions-legales">
          <a
            href="https://www.tutelaire.fr/mentions-legales-cookies"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales Tutélaire
          </a>
        </div>
        <div className="stepper__mentions-legales">
          <a
            href="https://amellis.fr/mentions-legales/"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales Amellis Mutuelles
          </a>
        </div>
      </div>
    </>
  );
}
