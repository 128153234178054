import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import {
  Form,
  InputButtonGroup,
  InputCheckbox,
  InputMask,
  InputText,
  useFormConfig,
} from "@lya-protect/lya-protect-form-library/dist/FormAPI";

import {
  FormNotice,
  FormTitle,
  Button,
  FormAction,
  FormField,
  FormGrid,
  FormRow,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { captureException } from "@sentry/react";
import axios from "axios";
import "./contactForm.scss";
import { environment } from "@src/MarqueBlanche";
import { LoaderAnimation } from "./LoaderAnimation";
import { displayToast } from "./Toaster";
import { ProtectionDonnees } from "./FooterLegal";

function cleanMaskedInput(val: unknown) {
  if (typeof val !== "string") return undefined;
  return val.replace(/\s/g, "");
}

const YOUSIGN_NAME_REGEX =
  /^[a-zA-Z0-9àáâãäåæçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ ß.’'()-]+$/;

const schema = zod.object({
  isSir: zod.boolean({ required_error: "Champ requis" }),
  name: zod
    .string({ required_error: "Champ requis" })
    .min(1, "Champ requis")
    .max(95, "Nom trop long")
    .regex(YOUSIGN_NAME_REGEX, { message: "Nom invalide" }),
  surname: zod
    .string({ required_error: "Champ requis" })
    .min(1, "Champ requis")
    .max(95, "Prénom trop long")
    .regex(YOUSIGN_NAME_REGEX, { message: "Prénom invalide" }),
  mail: zod
    .string({ required_error: "Champ requis" })
    .min(1, "Champ requis")
    .email({ message: "Email invalide" }),
  phoneNumber: zod.preprocess(
    cleanMaskedInput,
    zod
      .string({ required_error: "Champ requis" })
      .min(1, "Champ requis")
      .regex(/^0[1-5]\d{8}|06\d{8}|07[3-9]\d{7}$/, {
        message: "Numéro invalide",
      })
  ),
  acceptDataCollection: zod
    .boolean({ required_error: "Champ requis" })
    .refine((val) => val === true, "Champ requis"),
});

const getConsentLabel = () => {
  if (environment.is.default) {
    return "J'accepte le stockage et le traitement de mes données personnelles afin d'être recontacté par Tutélaire.";
  }
  let consent =
    "J'accepte le stockage et le traitement de mes données personnelles afin d'être recontacté par  ";
  if (environment.is.MUTAMI) consent += "Mutami ";
  if (environment.is.MSP) consent += "la Mutuelle des Services Publics ";
  if (environment.is.MCA) consent += "la Mutuelle Complémentaire d'Alsace ";
  if (environment.is.AMELLIS) consent += "Amellis Mutuelles";
  return consent;
};

type FormValues = zod.infer<typeof schema>;

const handleSubmit = (afterSubmit: () => void) => async (data: FormValues) => {
  try {
    await axios.post<null>(`//${process.env.VITE_URL_GATEWAY}/task/callme`, {
      civilite: data.isSir ? "Monsieur" : "Madame",
      nom: data.name,
      prenom: data.surname,
      contactMail: data.mail,
      contactTelephone: cleanMaskedInput(data.phoneNumber),
      consentements: [
        {
          description: getConsentLabel(),
          type: "CONSENTEMENT_TRAITEMENT_DONNEES",
        },
      ],
    });
  } catch (err) {
    captureException(err);
    console.error(err);
    displayToast("Une erreur inconnue est survenue", "error");
    throw err;
  }
  afterSubmit();
};

type ContactFormProps = {
  afterSubmit: () => void;
  hasTips?: boolean;
};

const getTitle = () => {
  if (environment.is.MUTAMI || environment.is.MSP || environment.is.AMELLIS) {
    return (
      <>
        Vous avez une question ou vous souhaitez être accompagné dans votre
        parcours de souscription ?
        <br />
        Un conseiller vous rappelle dans les meilleurs délais !
      </>
    );
  }
  return <>Laissez-nous vos coordonnées, un conseiller vous rappelera</>;
};

export function ContactForm({ afterSubmit, hasTips }: ContactFormProps) {
  const formConfig = useFormConfig<typeof schema, FormValues>({
    schema,
  });

  return (
    <div
      className="contact-form"
      data-submitting={formConfig.formState.isSubmitting}
      data-submitted={formConfig.formState.isSubmitSuccessful}
    >
      <div className="contact-form__form" data-hastips={hasTips}>
        <Form formConfig={formConfig} onSubmit={handleSubmit(afterSubmit)}>
          <FormGrid>
            <FormTitle>
              <h1>{getTitle()}</h1>
            </FormTitle>
            <FormNotice>
              <p>* Champs obligatoires.</p>
            </FormNotice>
            <FormRow>
              <FormField>
                <InputButtonGroup<FormValues, boolean>
                  className="contact-form__civilite-input"
                  variant="outline"
                  size="large"
                  name="isSir"
                  options={[
                    { label: "Monsieur", value: true },
                    { label: "Madame", value: false },
                  ]}
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField size="half">
                <InputText<FormValues> name="name" label="Nom" required />
              </FormField>
              <FormField size="half">
                <InputText<FormValues> name="surname" label="Prénom" required />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField size="full">
                <InputText<FormValues>
                  name="mail"
                  label="Adresse mail"
                  required
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField size="half">
                <InputMask<FormValues>
                  mask="99 99 99 99 99"
                  name="phoneNumber"
                  label="Numéro de téléphone"
                  required
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField size="full">
                <InputCheckbox<FormValues>
                  className="contact-form__checkbox"
                  name="acceptDataCollection"
                  required
                  label={getConsentLabel()}
                />
              </FormField>
            </FormRow>
            <FormAction>
              <Button
                className="side-pane-form__button"
                variant="outline"
                type="submit"
              >
                Me faire rappeler
              </Button>
            </FormAction>
          </FormGrid>
        </Form>
        <div className="contact-form__mentions-legales">
          <ProtectionDonnees />
        </div>
      </div>
      <div className="contact-form__pane contact-form__submitting">
        <LoaderAnimation />
      </div>
      <div className="contact-form__pane contact-form__success">
        <img src="/check-circle-white.svg" alt="" />
        <h1>Votre demande a bien été envoyée</h1>
      </div>
    </div>
  );
}
